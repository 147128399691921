/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // resizeContentBlocks is defined on the pages that need it
        if ( (typeof resizeContentBlocks !== 'undefined') && resizeContentBlocks ){

          var resizeBlocks = function(){
            (function(viewport){
              // Executes in SM, MD and LG breakpoints
              if( viewport.is('>xs') ) {
                 var $contentSections = $('.content-section');
                 $contentSections.each( function(){
                    var $sectionBlocks = $( this ).find('.content-blocks .block');
                    var maxHeight = 0;
                    $sectionBlocks.each( function() {
                      $( this ).css('height','');
                      if ( $( this ).height() > maxHeight ){
                        maxHeight = $( this ).height();
                      }
                    });
                    $sectionBlocks.height(maxHeight);
                 });
              }
            })(ResponsiveBootstrapToolkit);
          };

          resizeBlocks();
          var resizeTimeout = null;

          $( window ).on('resize', function(){
            clearTimeout( resizeTimeout );
            resizeTimeout = setTimeout( function(){
              resizeBlocks();
            }, 500 );
          });

          //resize periodically due to "scrambling"
          setInterval( function(){
            resizeBlocks();
          }, 2000 );

        }

        function scrollToClass( className, offset ){
          $('body, html').animate( { scrollTop: $( '.'+className )[0].offsetTop - offset } );
        }
        $('a[data-scroll-to]').on('click', function(e){
          e.stopPropagation();
          e.preventDefault();
          var className = $(e.currentTarget).data('scroll-to');
          var offset = $(".navbar.navbar-default").height();
          scrollToClass( className, offset );
        });
        // the functions below allow us to send mailchimp email signups
        // from a custom form

        function updateMailchimpEmail( input ){
          $('input#mc_mv_EMAIL').val( $(input).val() );
        }

        function showMessage( message, $feedbackForm ){
          var $feedback = $('<div></div>');
          var $newsletterFeedback = $('.newsletter-feedback');
          $newsletterFeedback.remove();
          $feedback.html( message );
          $feedback.addClass('newsletter-feedback');
          $feedback.hide();
          $feedbackForm.before($feedback);
          $feedback.slideDown();
        }

        function submitMailchimpEmail( $feedbackForm ){
          console.log('submit');
          showMessage('Adding you to our list...', $feedbackForm );
          $('#mc_message').bind('DOMSubtreeModified', function(e) {
            console.log('DOMSubtreeModified');
            console.log(e);
            var result = e.currentTarget.innerHTML;
            console.log( result );
            if ( result.length > 0 ) {
              showMessage(result, $feedbackForm);
              if ( result.indexOf('Success') >= 0 ){
                $feedbackForm.find('input').val('');
                $feedbackForm.find('input').blur();
              }
            }
          });
          try{
            $('#mc_signup_form').submit();
          }catch(e){
            console.log('error in mailchimp submit code. likely ui related');
            console.info(e);
          }
        }

        $('#email-form-blog-input').on('change', function(){
          updateMailchimpEmail( this );
        });

        $('#email-form-footer-input').on('change', function(){
          updateMailchimpEmail( this );
        });

        $('#email-form-footer-input, #email-form-blog-input').on("keypress", function(e) {
          console.info('e: ', e);
          var code = e.keyCode || e.which; 
          if (code  === 13) {
            e.preventDefault();
            var $feedbackForm = $( $( e.currentTarget ).parents('form')[0] );
            console.info('enter key form submit ', $feedbackForm );
            updateMailchimpEmail( e.currentTarget );
            submitMailchimpEmail( $feedbackForm );
            return false;
          }
        });

        $('.newsletter-email .input-group-addon').on('click', function(e){
          e.preventDefault();
          e.stopPropagation();
          submitMailchimpEmail( $( $( this ).parents('form')[0] ) );
        });

        $(function () {

          //enable case-studies tabs
          //$('#caseStudiesTabs a:last').tab('show');
          $('#caseStudiesTabs .nav a').click(function (e) {
              e.preventDefault();
              $(this).tab('show');
          });

        });

        //enable hotjar
        // (function(h,o,t,j,a,r){
        //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        //     h._hjSettings={hjid:122457,hjsv:5};
        //     a=o.getElementsByTagName('head')[0];
        //     r=o.createElement('script');r.async=1;
        //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        //     a.appendChild(r);
        // })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        (function(viewport){
          var mobileNav = $('.navbar-toggle').css('display') !== 'none';
          if( mobileNav ) {
              $('.navbar-toggle').on('click', function(e){
              setTimeout( function(){
                console.log('change menu');
                $('.navbar-collapse .dropdown').addClass('open');
                $('.navbar-collapse .dropdown-toggle').on('click', function(e1){
                  if ( $(this).parent().hasClass('open') ){
                    console.log('stop menu');
                    e1.stopPropagation();
                    e1.preventDefault();
                  }
                });
              }, 500);
            });
          }
        })(ResponsiveBootstrapToolkit);
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

          //swap words
          var WORD_SWAP_DELAY = 4000;
          var wordIndex = 1;
          var chopInterval = true;
          var cursor = "<span class='cursor'>|</span>";
          var typing = false;
          setInterval( function(){
            if ( !window.OMHWebsite.swapWords || typing ){
              return;
            }
            var words = window.OMHWebsite.swapWords;
            typing = true;
            var newWord = words[ wordIndex ].word;
            var oldWord = words[ ( wordIndex===0 )? words.length-1 : wordIndex-1 ].word;
            var chopWord = function(){
              oldWord = oldWord.slice(0,-1);
              $(".swap").html( oldWord + cursor );
              return oldWord.length;
            };
            var chopInterval = setInterval( function(){
              if ( chopWord() === 0 ){
                clearInterval( chopInterval );
                chopInterval = false;
              }
            }, 80 );
            var typeIndex = 0;
            var typeInterval = setInterval( function(){
              if( !chopInterval ){
                oldWord += newWord.charAt( typeIndex );
                $(".swap").html( oldWord + cursor );
                typeIndex++;
                if ( typeIndex === newWord.length ){
                  clearInterval( typeInterval );
                  typeInterval = false;
                  $(".swap").html( oldWord );
                  typing = false;
                }
              }
            }, 140);
            wordIndex++;
            wordIndex = wordIndex%words.length;
          }, WORD_SWAP_DELAY );


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'people': {
      init: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
